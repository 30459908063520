/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import useLiveControl from "../controlled/useLiveControl"

function SEO({ description, lang, meta, title, link, canonical }) {
  const [isBlogDetailsPage, setIsBlogDetailsPage] = useState(false)
  const { baseUrl } = useLiveControl()

  useEffect(() => {
    setIsBlogDetailsPage(() => document.getElementById("blogDetail") !== null)
  }, [])

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            faceBookImage
          }
        }
      }
    `
  )

  console.log("Author: " + baseUrl + site.siteMetadata.author)

  const metaDescription = description || site.siteMetadata.description
  const canonicalLink = "" || canonical
  const seoTitle = title + " | " + site.siteMetadata.title
  const faceBookImageUrl = baseUrl + site.siteMetadata.faceBookImage

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: seoTitle,
        },
        !isBlogDetailsPage
          ? {
              property: `og:image`,
              content: faceBookImageUrl,
            }
          : {},
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: seoTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
      // link={[
      //   {
      //     rel: "canonical",
      //     href: canonicalLink,
      //   },
      // ].concat(link)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  link: [],
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
 // canonical: PropTypes.string,
  link: PropTypes.arrayOf(PropTypes.object),
}

export default SEO
