import React from "react"
import { Link } from "gatsby"
import Helmet from "react-helmet"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

const InteriorRemodellingFinancingPage = () => {
  return (
    <Layout>
      <Helmet>
        <script type="application/ld+json" async={true}>
          {`{
 "@context": "https://schema.org/",
 "@type": "Product",
 "name": "Interior Remodeling Contractor Financing",
 "image": "",
 "description": "Give your interior remodeling clients flexible and convenient loan products and boost your business. Learn how EnerBank’s contractor financing options can help."
} `}
        </script>
      </Helmet>
      <Seo
        title={"Contractor Financing for Interior Remodeling"}
        description={
          "Give your interior remodeling clients flexible and convenient loan products and boost your business."
        }
      />
      <section
        class="page-hero"
        style={{
          backgroundImage:
            "url(" +
            "http://gbdevpress.enerbank.com/wp-content/uploads/2022/09/interior-remodelling-hero_02-scaled.jpg" +
            ")",
        }}
      >
        <div class="page-hero-inner d-flex justify-content-center align-items-center">
          <div class="container text-center">
            <h1 class="text-white">Interior Remodeling Contractor Financing</h1>
          </div>
        </div>
      </section>
      <section className="bg-light-gray py-5">
        <div className="container">
          <div className="justify-content-center">
            <p>
              Give your interior remodeling clients flexible and convenient loan
              products and boost your business. Learn how EnerBank’s{" "}
              <Link
                to="/contractor-financing/"
                className="text-blue font-weight-bold"
              >
                contractor financing
              </Link>{" "}
              options can help.
            </p>
          </div>
        </div>
      </section>

      <section className="bg-light-gray">
        <div className="bg-green py-3">
          <div className="container">
            <h2 className="text-center text-white">
              Contractor Financing for Interior Remodelers
            </h2>
          </div>
        </div>
        <div className="container">
          <div className="row py-5">
            <div className="col-12">
              <p className="mb-4">
                As a contractor, it can be difficult to find ways to grow your
                interior remodeling business. That’s where contractor financing
                from Regions | EnerBank comes into play. Our payment options are
                a proven method to help you increase your business while helping
                your customers get the home of their dreams.
              </p>
              <p>
                Check out some of the ways that financing for contractors can
                help you bring in more projects and help take your business to
                the next level.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-light-gray">
        <div className="bg-charcoal py-3">
          <div className="container">
            <h2 className="text-center text-white">
              Benefits of Contractor Financing for Interior Remodeling
            </h2>
          </div>
        </div>
        <div className="container">
          <div className="row py-5">
            <div className="col-12">
              <p className="mb-4">
                Many homeowners underestimate the cost of their projects,
                especially with increasing prices, and it can often lead to a
                major case of sticker shock, causing the customer to take a step
                back, reduce the size of the project, or postpone the project
                altogether. By providing a choice of convenient and affordable
                loan options, you can help customers get the end product they
                want now, helping you close more jobs.
              </p>
              <p className="mb-4">
                Our research has shown that once a customer is approved
                for&nbsp;
                <Link
                  to="/contractor-financing/"
                  className="text-blue font-weight-bold"
                >
                  contractor financing
                </Link>
                , they are 94% more likely to complete the job with you, meaning
                they’re less likely to shop around*. That’s good for business!
              </p>
              <p>
                <em>
                  *Based on the Brickyard Study commissioned by EnerBank USA,
                  2018.
                </em>
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-darker-grey py-5">
        <div className="container">
          <h2 className="text-center mb-5 section-title-small">
            Contractors who work with EnerBank experience many benefits,
            including:
          </h2>
          <div className="row">
            <div class="col-lg-2 col-4 mb-3 mb-lg-0">
              <div className="int-remodelling-icon-box">
                <div class="int-remodelling-icon">
                  <img
                    src="http://gbdevpress.enerbank.com/wp-content/uploads/2022/08/improve-cashflow.png"
                    alt="improve cash flow"
                  />
                </div>
                <h3 class="text-center font-weight-bold">
                  Improve <br /> Cash Flow
                </h3>
              </div>
            </div>
            <div class="col-lg-2 col-4 mb-3 mb-lg-0">
              <div className="int-remodelling-icon-box">
                <div class="int-remodelling-icon">
                  <img
                    src="http://gbdevpress.enerbank.com/wp-content/uploads/2022/08/increase-leads.png"
                    alt="increase leads"
                  />
                </div>
                <h3 class="text-center font-weight-bold">
                  Increase <br /> Leads
                </h3>
              </div>
            </div>
            <div class="col-lg-2 col-4 mb-3 mb-lg-0">
              <div className="int-remodelling-icon-box">
                <div class="int-remodelling-icon">
                  <img
                    src="http://gbdevpress.enerbank.com/wp-content/uploads/2022/08/boost-close-rates.png"
                    alt="boost close rates"
                  />
                </div>
                <h3 class="text-center font-weight-bold">
                  Boost <br /> Close Rate
                </h3>
              </div>
            </div>

            <div class="col-lg-2 col-4 mb-3 mb-lg-0">
              <div className="int-remodelling-icon-box">
                <div class="int-remodelling-icon">
                  <img
                    src="http://gbdevpress.enerbank.com/wp-content/uploads/2022/08/grow-project-size.png"
                    alt="grow average project size"
                  />
                </div>
                <h3 class="text-center font-weight-bold">
                  Grow Average <br /> Project Size
                </h3>
              </div>
            </div>

            <div class="col-lg-2 col-4 mb-3 mb-lg-0">
              <div className="int-remodelling-icon-box">
                <div class="int-remodelling-icon">
                  <img
                    src="http://gbdevpress.enerbank.com/wp-content/uploads/2022/08/eliminate-discounting.png"
                    alt="emliminate discounting"
                  />
                </div>
                <h3 class="text-center font-weight-bold">
                  Eliminate <br /> Discounting
                </h3>
              </div>
            </div>

            <div class="col-lg-2 col-4 mb-3 mb-lg-0">
              <div className="int-remodelling-icon-box">
                <div class="int-remodelling-icon">
                  <img
                    src="http://gbdevpress.enerbank.com/wp-content/uploads/2022/08/reduce-cancellations.png"
                    alt="reduce cancellations"
                  />
                </div>
                <h3 class="text-center font-weight-bold">
                  Reduce <br /> Cancellations
                </h3>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-light-gray">
        <div className="bg-blue  py-3">
          <div className="container">
            <h2 className="text-center text-white">
              Start Offering Financing as a Contractor
            </h2>
          </div>
        </div>
        <div className="row">
          <div className="col-12 itm-start-area">
            <div className="int-remodeling-image-left">
              <img
                src="http://gbdevpress.enerbank.com/wp-content/uploads/2022/08/Contractor_laptop-scaled.jpg"
                alt="offering contractor financing"
              />
            </div>

            <div className="int-remodeling-offering int-remodeling-boxed-text">
              <p>
                Offering financing from EnerBank to your interior remodeling
                customers is easier than you may think. To begin, you’ll need to
                provide basic information, such as how long you’ve been in
                business, yearly revenue, company name, and more. Once we review
                your application, a member of our team will reach out with next
                steps.
              </p>
              <p className="mt-4">
                Once your program is up and running, you won’t be left to
                navigate the process alone. Our team is here to help however
                they can, including providing you and your team with customer
                support, training, marketing materials, and more.
              </p>
              <p className="mt-4">
                As an added bonus, incorporating payment options into your
                existing sales process is simple. With three paperless
                application methods and fast loan decisions, you can complete
                the loan process while in the customer’s home.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-light-gray">
        <div className="bg-green py-3">
          <div className="container">
            <h2 className="text-center text-white">
              What Makes EnerBank Different When it Comes to Contractor
              Financing?
            </h2>
          </div>
        </div>
        <div className="container py-5">
          <div className="row">
            <div className="col-12">
              <p className="mb-4">
                When looking for a financing provider, you want to make sure
                they tick all the boxes of what you need. EnerBank not only
                covers the basics when it comes to interior remodel financing,
                but provides additional resources that you won’t find anywhere
                else:
              </p>
            </div>
          </div>
          <article>
            <dl class="remodeling-eb-difference">
              <div className="mb-4">
                <dt className="position-relative">
                  Does the provider specialize in remodeling project financing?
                </dt>
                <dd>
                  Yes. For over 20 years, EnerBank has focused on the home
                  improvement industry, including interior remodeling. This
                  means we have developed the loans, tools, and resources to
                  ensure that both you and your customer have a great experience
                  working with us.
                </dd>
              </div>
              <div className="mb-4">
                <dt className="position-relative">
                  Are there any hidden fees when working with EnerBank?
                </dt>
                <dd>
                  No. We are totally transparent about the costs to you and your
                  customers. Plus, with competitively priced contractor fees,
                  you’ll know you’re getting the most benefit for your dollar.
                </dd>
              </div>
              <div className="mb-4">
                <dt className="position-relative">
                  Is the provider stable and secure?
                </dt>
                <dd>
                  Yes. EnerBank has decades of experience providing loan
                  products for home improvement contractors. We fund all of our
                  own loans, which means there’s no middle man when you get
                  paid, and we never pull back funds in the event a homeowner
                  stops making payments.
                </dd>
              </div>
            </dl>
          </article>
        </div>
      </section>
      <section className="bg-light-gray">
        <div className="bg-charcoal py-3">
          <div className="container">
            <h2 className="text-center text-white">Why Choose EnerBank?</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-5 order-xl-12">
            <img
              src="http://gbdevpress.enerbank.com/wp-content/uploads/2022/08/roadside-scaled.jpg"
              alt="why choose EnerBank"
            />
          </div>
          <div className="col-xl-7 order-xl-1">
            <div class="boxed-half d-flex align-items-center int-remodeling-boxed-text">
              <p>
                When it comes to financing for interior remodeling, we know what
                we’re doing. You can trust us with the day-to-day needs that a
                financing program entails so you can focus on what your business
                needs. Our goal is to ensure that not only do you have a great
                experience working with us, but your customer does too.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-light-gray">
        <div className="bg-blue py-3">
          <div className="container">
            <h2 className="text-center text-white">
              Find Out More About Contractor Financing Here
            </h2>
          </div>
        </div>
        <div className="container">
          <div class="row py-5 align-items-center">
            <div class="col-lg-2 text-center">
              <img
                src="http://gbdevpress.enerbank.com/wp-content/uploads/2022/08/notes.png"
                alt="take advantage of benefits"
              />
            </div>
            <div class="col-lg-8">
              <p class="mt-4">
                Ready to start taking advantage of the benefits that
                offering&nbsp;
                <Link
                  to="/contractor-financing/"
                  className="text-blue font-weight-bold"
                >
                  contractor financing
                </Link>{" "}
                has for your business? Sign up with EnerBank today and watch
                your business continue to grow.{" "}
                <Link to="/sign-up/" className="text-blue font-weight-bold">
                  Sign up here!
                </Link>
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default InteriorRemodellingFinancingPage
